<template>
  <div>
    <a-modal
      :visible="visible"
      title="翻译词条"
      width="60%"
      centered
      okText="确认"
      cancelText="取消"
      @ok="() => onSubmit()"
      @cancel="() => hideDialog()"
    >
      <a-descriptions title="词条数据" bordered style="margin-top: 1%; margin-bottom: 3%">
        <a-descriptions-item label="词条中文"> {{ form.curCN }} </a-descriptions-item>
        <a-descriptions-item label="实体名称"> {{ form.entityName }} </a-descriptions-item>
        <a-descriptions-item label="语言编号"> {{ form.languageId }} </a-descriptions-item>
        <a-descriptions-item label="词条上下文"> {{ form.context }} </a-descriptions-item>
        <a-descriptions-item label="创建时间">
          {{ moment(form.createdTime).format('YYYY年MM月DD日') }}
        </a-descriptions-item>
        <a-descriptions-item label="上次修改时间">
          {{ moment(form.lastModificationTime).format('YYYY年MM月DD日') }}
        </a-descriptions-item>
        <a-descriptions-item label="key"> {{ form.key }} </a-descriptions-item>
      </a-descriptions>
      <h4 v-if="keyInfomations.length > 0">关联数据</h4>
      <a-card class="entryCard" v-if="keyInfomations.length > 0">
        <div v-for="(keyInformation, index) in keyInfomations" :key="keyInformation.id">
          <a-descriptions :column="4">
            <a-descriptions-item label="id"> {{ keyInformation.id }} </a-descriptions-item>
            <a-descriptions-item label="名称"> {{ keyInformation.name }} </a-descriptions-item>
            <a-descriptions-item label="标识符"> {{ keyInformation.identifier }} </a-descriptions-item>
            <a-descriptions-item>
              <a-popover
                title="关联的物模型"
                trigger="click"
                placement="left"
                :overlayStyle="{ 'max-width': '800px', 'max-height': '400px', 'overflow-y': 'auto' }"
              >
                <template #content>
                  <div v-if="keyInformation.productDtos.length !== 0">
                    <a-table
                      :columns="columns"
                      :data-source="keyInformation.productDtos"
                      size="small"
                      :pagination="false"
                      childrenColumnName=""
                      rowKey="productId"
                    >
                      <template #protocol="scope">
                        <span>{{ productProtocols(scope) }}</span>
                      </template>
                      <template #type="scope">
                        <span>{{ productTypes(scope) }}</span>
                      </template>
                      <template #addressType="scope">
                        <span>{{ productAddressTypes(scope) }}</span>
                      </template>
                      <template #expandedRowRender="record">
                        <a-card title="配置项列表" v-if="record.configurationItems.length != 0">
                          <a-descriptions
                            :column="2"
                            v-for="configuration in record.configurationItems"
                            :key="configuration.id"
                          >
                            <a-descriptions-item label="配置项设备端标识符">{{
                              configuration.identifier
                            }}</a-descriptions-item>
                            <a-descriptions-item label="配置描述">{{ configuration.remark }}</a-descriptions-item>
                          </a-descriptions>
                        </a-card>
                        <a-card title="产品属性列表" v-if="record.properties.length != 0">
                          <a-descriptions :column="2" v-for="property in record.properties" :key="property.id">
                            <a-descriptions-item label="属性标识符">{{ property.identifier }}</a-descriptions-item>
                            <a-descriptions-item label="属性名称">{{ property.name }}</a-descriptions-item>
                          </a-descriptions>
                        </a-card>
                        <a-card title="产品支持的服务列表" v-if="record.services.length != 0">
                          <a-descriptions :column="2" v-for="service in record.services" :key="service.id">
                            <a-descriptions-item label="服务标识符">{{ service.identifier }}</a-descriptions-item>
                            <a-descriptions-item label="服务描述">{{ service.remark }}</a-descriptions-item>
                          </a-descriptions>
                        </a-card>
                        <a-card title="关联负载设备类型列表" v-if="record.loadedDeviceTypes.length != 0">
                          <a-descriptions
                            :column="2"
                            v-for="loadedDeviceType in record.loadedDeviceTypes"
                            :key="loadedDeviceType.id"
                          >
                            <a-descriptions-item label="负载设备类型Id">{{
                              loadedDeviceType.loadedDeviceTypeId
                            }}</a-descriptions-item>
                            <a-descriptions-item label="负载设备类型名称">{{
                              loadedDeviceType.name
                            }}</a-descriptions-item>
                          </a-descriptions>
                        </a-card>
                      </template>
                    </a-table>
                  </div>
                  <div v-else><span>暂无关联数据</span></div>
                </template>
                <a-button type="link">关联的物模型</a-button>
              </a-popover>
            </a-descriptions-item>
          </a-descriptions>
          <a-divider v-if="keyInfomations.length > 1 && index !== keyInfomations.length - 1" />
        </div>
      </a-card>
      <a-form-model layout="vertical" :model="form" :rules="rules" ref="form">
        <a-form-model-item label="状态" prop="status">
          <a-select
            v-model="form.status"
            placeholder="请选择状态"
            class="search-options"
            optionFilterProp="children"
            @change="updateStatus"
          >
            <a-select-option :value="$constRepository.entry.status.enable">启用</a-select-option>
            <a-select-option :value="$constRepository.entry.status.disabled">禁用</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="内容" prop="value">
          <a-textarea :auto-size="{ minRows: 4 }" width="100%" v-model="form.value" placeholder="请输入词条内容">
          </a-textarea>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
import clonedeep from 'lodash.clonedeep';
import moment from 'moment';

export default {
  name: 'EntryFormModal',
  components: {},
  mounted() {},
  data() {
    return {
      rules: {
        id: [{ required: true, message: '国际化语言编号 不能为空', trigger: 'blur' }],
      },
      visible: false,
      resultCallBack: {
        onSubmit: Function,
      },
      moment,
      form: {},
      keyInfomations: [],
      metaData: {},
      columns: [
        {
          title: '产品ID',
          dataIndex: 'productId',
          key: 'productId',
          align: 'center',
        },
        {
          title: '产品名称',
          dataIndex: 'name',
          key: 'name',
          align: 'center',
        },
        {
          title: '驱动标识符',
          dataIndex: 'driver',
          key: 'driver',
          align: 'center',
        },
        {
          title: '协议类型',
          dataIndex: 'protocol',
          key: 'protocol',
          align: 'center',
          scopedSlots: { customRender: 'protocol' },
        },
        {
          title: '产品类型',
          dataIndex: 'type',
          key: 'type',
          align: 'center',
          scopedSlots: { customRender: 'type' },
        },
        {
          title: '产品地址类型',
          dataIndex: 'addressType',
          key: 'addressType',
          align: 'center',
          scopedSlots: { customRender: 'addressType' },
        },
        {
          title: '版本',
          dataIndex: 'version',
          key: 'version',
          align: 'center',
        },
      ],
    };
  },
  methods: {
    cancel() {
      this.$router.back();
    },
    hideDialog() {
      this.visible = false;
      this.form = {};
    },
    async showDialog(pararms, callback) {
      this.keyInfomations = [];
      this.form = clonedeep(pararms);
      this.resultCallBack.onSubmit = callback;
      await Promise.all([this.getKeyInfomation(), this.getMetaData()]);
      this.visible = true;
    },
    updateStatus() {
      this.$apiManager.entry.updateEntryStatus(this.form).then(() => {
        this.$message.success('更新状态成功');
        this.$parent.loadedPageData();
      });
    },
    onSubmit() {
      this.$refs.form.validate((relsValid) => {
        if (relsValid) {
          this.resultCallBack.onSubmit(this.form);
          this.hideDialog();
        }
      });
    },
    async getKeyInfomation() {
      const params = {
        key: this.form.key,
        entity: this.form.entity,
      };
      const res = await this.$apiManager.entry.getKeyInfomation(params);
      this.keyInfomations = res.data.keyInformationDtos;
    },
    async getMetaData() {
      const response = await this.$apiManager.productMetaData.getMetaData();
      this.metaData = response.data;
    },
  },
  computed: {
    productProtocols() {
      return (protocol) => {
        const protocolsName = this.metaData.productProtocols?.find((md) => md.value === protocol);
        return protocolsName ? protocolsName.name : '';
      };
    },
    productTypes() {
      return (type) => {
        const typeName = this.metaData.productTypes.find((md) => md.value === type);
        return typeName ? typeName.name : '';
      };
    },
    productAddressTypes() {
      return (addressTypes) => {
        const addressTypeName = this.metaData.productAddressTypes.find((md) => md.value === addressTypes);
        return addressTypeName ? addressTypeName.name : '';
      };
    },
  },
};
</script>
<style lang="less" scoped>
.entryCard {
  overflow-y: auto;
  max-height: 300px;
  margin-bottom: 10px;
  /deep/ .ant-descriptions .ant-descriptions-view .ant-descriptions-row {
    td:first-child {
      width: 35%;
    }
    td:last-child {
      width: 150px;
    }
  }
}
</style>
