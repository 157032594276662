<template>
  <div>
    <a-modal
      :visible="visible"
      title="请选择导出的词条条件"
      width="40%"
      centered
      okText="确认"
      cancelText="取消"
      @ok="() => onSubmit()"
      @cancel="() => hideDialog()"
    >
      <a-form-model layout="vertical" :model="form" :rules="rules" ref="form">
        <a-form-model-item label="词条状态" prop="translated">
          <a-switch
            class="search-switch"
            v-model="form.translated"
            checked-children="全部"
            un-checked-children="待翻译"
            default-checked
          />
        </a-form-model-item>
        <a-form-model-item label="词条实体" prop="entityId">
          <a-select
            v-model="form.entityId"
            allowClear
            placeholder="请选择词条实体"
            showSearch
            class="search-options"
            optionFilterProp="children"
          >
            <a-select-option v-for="entity in entityList" :key="entity.id">{{ entity.name }}</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="词条语言" prop="languageId">
          <a-select
            v-model="form.languageId"
            allowClear
            placeholder="请选择词条语言"
            showSearch
            class="search-options"
            optionFilterProp="children"
          >
            <a-select-option :disabled="language.id === 'zh_CN'" v-for="language in languageList" :key="language.id">{{
              language.name
            }}</a-select-option>
          </a-select>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
export default {
  name: 'ExportEntryFormModal',
  components: {},
  mounted() {},
  data() {
    return {
      rules: {
        entityId: [{ required: true, message: '词条实体 不能为空', trigger: 'blur' }],
        languageId: [{ required: true, message: '词条语言 不能为空', trigger: 'blur' }],
      },
      entityList: [],
      languageList: [],
      visible: false,
      isUpdateMode: false,
      resultCallBack: {
        onSubmit: Function,
      },
      form: {
        translated: false,
      },
    };
  },
  methods: {
    cancel() {
      this.$router.back();
    },
    hideDialog() {
      this.visible = false;
      this.form = {};
    },
    showDialog(pararms, callback) {
      this.isUpdateMode = true;
      this.entityList = pararms.entityList;
      this.languageList = pararms.languageList;
      this.resultCallBack.onSubmit = callback;
      this.visible = true;
    },
    onSubmit() {
      this.$refs.form.validate((relsValid) => {
        if (relsValid) {
          this.resultCallBack.onSubmit(this.form);
          this.hideDialog();
        }
      });
    },
  },
};
</script>
