<template>
  <div class="content-layout">
    <div class="property-operations">
      <div class="export-btn-group">
        <label for="fileinp">
          <a-button type="primary" class="btn"
            >批量导入翻译
            <input
              type="file"
              name="filename"
              ref="upload"
              accept=".xls, .xlsx"
              class="outputlist_upload"
              id="fileinp"
              multiple="multiple"
            />
          </a-button>
        </label>
        <a-button type="primary" class="add-btn" @click="handleExport">导出翻译模板</a-button>
      </div>
      <div class="data-filter">
        <a-switch
          class="search-switch"
          @change="loadedPageData"
          v-model="requestParams.translated"
          checked-children="全部"
          un-checked-children="待翻译"
          default-checked
        />
        <a-select
          v-model="requestParams.entityId"
          allowClear
          placeholder="请选择实体"
          showSearch
          class="search-options"
          optionFilterProp="label"
          @change="loadedPageData"
        >
          <a-select-option v-for="entity in entityList" :key="entity.id" :label="entity.name">{{
            entity.name
          }}</a-select-option>
        </a-select>
        <a-select
          v-model="requestParams.languageId"
          allowClear
          placeholder="请选择语言"
          showSearch
          class="short-search-options"
          optionFilterProp="children"
          @change="loadedPageData"
        >
          <a-select-option v-for="language in languageList" :key="language.id">{{ language.name }}</a-select-option>
        </a-select>
        <a-select
          v-model="requestParams.status"
          allowClear
          placeholder="请选择状态"
          class="short-search-options"
          optionFilterProp="children"
          @change="loadedPageData"
        >
          <a-select-option :value="$constRepository.entry.status.enable">启用</a-select-option>
          <a-select-option :value="$constRepository.entry.status.disabled">禁用</a-select-option>
        </a-select>
        <a-input
          clearable
          placeholder="输入词条中文或内容"
          v-model="requestParams.searchKey"
          class="search-keyword"
          @keyup.enter.native="doSearch"
        ></a-input>
        <a-button class="search-btn" type="primary" @click="doSearch()">搜索</a-button>
      </div>
    </div>
    <a-table
      size="middle"
      :pagination="false"
      :columns="this.$constRepository.entry.columns"
      :data-source="tableDate"
      class="property-table"
      rowKey="id"
    >
      <template slot="status" slot-scope="text, record">
        <a-switch
          checked-children="启用"
          un-checked-children="禁用"
          :disabled="!$hasPermissions(['rcs:entry_i18n:update'])"
          :checkedValue="$constRepository.entry.status.enable"
          :unCheckedValue="$constRepository.entry.status.disabled"
          :checked="switchStatus(record.status)"
          @change="handleStatusChange(record)"
        />
      </template>
      <template slot="action" slot-scope="scope">
        <a-button v-if="$hasPermissions(['rcs:entry_i18n:update'])" class="green-btn" ghost @click="handleEdit(scope)">翻译</a-button>
      </template>
    </a-table>
    <div class="pagination">
      <a-pagination
        :current="pageInfo.page"
        :show-total="(total) => `共 ${pageInfo.totalElements} 条`"
        show-quick-jumper
        show-size-changer
        :total="pageInfo.totalElements"
        @showSizeChange="onShowSizeChange"
        @change="onChange"
        :pageSizeOptions="['10', '20', '30', '40']"
      />
      <br />
    </div>
    <entry-form-modal ref="entryFormModal" />
    <export-entry-form-modal ref="exportEntryFormModal" />
  </div>
</template>

<script>
import moment from 'moment';
import XLSX from 'xlsx';
import EntryFormModal from './components/EntryFormModal';
import ExportEntryFormModal from './components/ExportEntryFormModal';

export default {
  name: 'EntryManagement',
  components: { EntryFormModal, ExportEntryFormModal },
  activated() {
    this.loadedPageData();
    this.listI18nEntities();
    this.listLanguages();
    this.$refs.upload.addEventListener('change', (e) => {
      this.readExcel(e);
    });
  },
  data() {
    return {
      searchKeyword: '',
      requestParams: {
        translated: false,
      },
      tableDate: [],
      moment,
      entityList: [],
      languageList: [],
      excelData: [],
      pageInfo: {
        page: 1,
        size: 10,
        totalElements: 0,
        totalPages: 0,
      },
    };
  },
  methods: {
    doSearch() {
      this.pageInfo.page = 1;
      this.loadedPageData();
    },
    async listEntry() {
      const params = {
        translated: this.requestParams.translated,
        status: this.requestParams.status,
        searchKey: this.requestParams.searchKey,
        languageId: this.requestParams.languageId,
        page: this.pageInfo.page - 1,
        size: this.pageInfo.size,
      };
      const response = await this.$apiManager.entry.getEntries(
        this.requestParams.entityId ? this.requestParams.entityId : '-',
        params,
      );
      this.tableDate = response.data.elements;
      this.$set(this.pageInfo, 'totalElements', response.data.totalElements);
      this.$set(this.pageInfo, 'totalPages', response.data.totalPages);
      this.$set(this.pageInfo, 'currentPage', response.data.currentPage);
    },
    async loadedPageData() {
      await this.listEntry();
      if (this.pageInfo.currentPage + 1 > this.pageInfo.totalPages && this.pageInfo.currentPage) {
        this.pageInfo.page = 1;
        await this.listEntry();
      }
    },
    handleEdit(record) {
      this.$refs.entryFormModal.showDialog(record, (entryForm) => {
        this.$apiManager.entry.updateEntry(entryForm).then(() => {
          this.$message.success('翻译成功');
          this.loadedPageData();
        });
      });
    },
    updateEntries(entries, info = '翻译成功') {
      this.$apiManager.entry.updateEntries(entries).then(() => {
        this.$message.success(info);
        this.loadedPageData();
      });
    },
    handleExport() {
      const params = {
        entityList: this.entityList,
        languageList: this.languageList,
      };
      this.$refs.exportEntryFormModal.showDialog(params, this.exportExcel);
    },
    onShowSizeChange(current, pageSize) {
      this.pageInfo.page = 1;
      this.$set(this.pageInfo, 'size', pageSize);
      this.loadedPageData();
    },
    onChange(pageNumber) {
      this.$set(this.pageInfo, 'page', pageNumber);
      this.loadedPageData();
    },
    statusName(status) {
      if (status === this.$constRepository.entry.status.enable) {
        return '启用';
      }
      if (status === this.$constRepository.entry.status.disabled) {
        return '禁用';
      }
      return '未知';
    },
    async listI18nEntities() {
      const response = await this.$apiManager.i18nEntity.getI18nEntities({ unpaged: true });
      this.entityList = response.data.elements;
    },
    async listLanguages() {
      const response = await this.$apiManager.language.getLanguages({ unpaged: true });
      this.languageList = response.data.elements;
    },
    async exportExcel(requestParams) {
      if (requestParams.languageId && requestParams.entityId) {
        const params = {
          translated: requestParams.translated,
          status: this.$constRepository.entry.status.enable,
          languageId: requestParams.languageId,
          unpaged: true,
        };
        const response = await this.$apiManager.entry.getEntries(requestParams.entityId, params);
        if (response.data.elements.length) {
          const wb = XLSX.utils.book_new();
          const json = response.data.elements.map((entry) => ({
            id: entry.id,
            entityId: entry.entityId,
            key: entry.key,
            实体: entry.entity,
            语言编号: entry.languageId,
            词条上下文: entry.context,
            中文内容: entry.curCN,
            翻译内容: entry.value,
          }));
          const filename = `${json[0].实体}实体${requestParams.languageId}语言翻译内容.xlsx`;
          const wsName = 'SheetJS';
          const ws = XLSX.utils.json_to_sheet(json, { raw: true });
          XLSX.utils.book_append_sheet(wb, ws, wsName);
          XLSX.writeFile(wb, filename);
        } else {
          this.$message.warning('该词条实体无数据');
        }
      } else {
        this.$message.warning('请选择需要导出的实体和语言');
      }
    },
    readExcel(e) {
      const { files } = e.target;
      if (!/\.(xls|xlsx)$/.test(files[0].name.toLowerCase())) {
        this.$message.error('上传格式不正确，请上传xls或者xlsx格式');
      }
      for (let i = 0; i < files.length; i += 1) {
        this.analysisExcel(files[i]);
      }
    },
    analysisExcel(file) {
      const fileReader = new FileReader(file);
      fileReader.onload = (ev) => {
        try {
          const data = ev.target.result;
          const workbook = XLSX.read(data, {
            type: 'binary',
            raw: true,
          });
          const wsname = workbook.SheetNames[0]; // 取第一张表
          const ws = XLSX.utils.sheet_to_json(workbook.Sheets[wsname], { raw: true }); // 生成json表格内容
          // 编辑数据
          const params = [];
          ws.forEach((entry) => {
            const newData = {
              id: entry.id,
              entityId: entry.entityId,
              key: entry.key,
              languageId: entry.语言编号,
              value: entry.翻译内容,
              context: entry.词条上下文,
            };
            if (newData.value) {
              params.push(newData);
            }
          });
          this.updateEntries(params, '导入成功');
        } catch (error) {
          this.$message.error('导入失败');
        }
      };
      return fileReader.readAsBinaryString(file);
    },
    handleStatusChange(record) {
      const params = record;
      params.status = record.status === 1 ? 2 : 1;
      this.$apiManager.entry.updateEntryStatus(params).then(() => {
        this.$message.success('更新状态成功');
        this.loadedPageData();
      });
    },
  },
  computed: {
    switchStatus: {
      get() {
        return (status) => status === this.$constRepository.entry.status.enable;
      },
    },
  },
};
</script>

<style lang="less" scoped>
.content-layout {
  height: auto;
}
.green-btn {
  color: green;
  border: none;
}
.red-btn {
  color: red;
  border: none;
}
#fileinp {
  float: left;
  margin-left: 2%;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  .btn {
    margin-left: 2%;
  }
}
.property-operations {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1%;
  .export-btn-group {
    width: 30%;
  }
  .add-btn {
    margin: 10px 0px;
    margin-left: 3%;
  }
  .data-filter {
    display: flex;
    flex-direction: row;
    align-items: center;
    .tip {
      color: #4c4c4c;
    }
    .date-picker-style {
      margin: 0 10px;
    }
    .search-switch {
      width: 90px;
      margin-right: 5%;
    }
    .search-options {
      width: 200px;
      margin-right: 1%;
    }
    .short-search-options {
      width: 140px;
      margin-right: 1%;
    }
    .search-keyword {
      width: 160px;
    }
    .search-btn {
      margin: 10px 0px;
      margin-left: 3%;
    }
  }
  .property-table {
    margin-top: 2%;
  }
}
.pagination {
  margin-top: 3%;
}
</style>
